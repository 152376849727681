import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`No Classes today, our annual True Grit team competition is today.
 Stop by and cheer on the teams! 9am-3pm.`}</strong></p>
    <p>{`WOD 1`}</p>
    <p>{`Male/Female complete:`}</p>
    <p>{`50 Calorie Row`}</p>
    <p>{`Six 50ft Backwards Sled Drags (135`}{`#`}{`)`}</p>
    <p>{`100 Wall Balls (20/14)`}</p>
    <p>{`WOD 2`}</p>
    <p>{`Each individual completes:`}</p>
    <p>{`30-Deadlifts`}</p>
    <p>{`20-Power Cleans`}</p>
    <p>{`10 Thrusters`}</p>
    <p>{`(135/95)`}</p>
    <p>{`WOD 3`}</p>
    <p>{`4 Person team completes:`}</p>
    <p>{`100 Calorie Assault Bike`}</p>
    <p>{`100 Burpee Box Jump Overs`}</p>
    <p>{`100 DB Snatch (50/35)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      